<template>
  <v-card
    width="100%"
    flat
  >
    <v-row
      no-gutters
      :style="{ height: '12%' }"
    >
      <v-card-title :style="{ borderBottom: '1px solid #ccc', width: '100%', height: '60px', fontSize: '2.5vh' }">
        {{ title }}
      </v-card-title>
    </v-row>
    <v-row
      no-gutters
      :style="{ height: '88%' }"
      class="pt-2"
    >
      <v-col>                
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Solicita senha ao abrir o caixa com valores diferentes do fechamento:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Solicita senha para lançamentos de caixa:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row> 
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Solicita senha para troca/devolução:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Indica fechamento de caixa as cegas:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row>              
        <v-row no-gutters>            
          <v-col
            sm="9"
            md="10"
            lg="10"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"
          >
            Margem de erro para ignorar no fechamento:
          </v-col>         
          <v-col
            sm="2"
            md="2"
            lg="1"
            :cols="$vssWidth < 600 ? 3 : 8"
            class="pt-2"
          >
            <v-text-field
              value=""
              outlined
              dense
              flat
            />
          </v-col>          
        </v-row>  
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {


    }
  },
  watch: {

  },
  mounted() {

  },
  methods: {

  },

}
</script>